import { utils } from "vincent-utils";

const devHost = import.meta.env.VITE_DEV_HOST || "";
export default async function poster<R>(
  url: string,
  bodyObj: { [key: string]: any }
): Promise<R> {
  const cleanedUrl = url.replace(/^\//, "");
  //const params = new URLSearchParams({ ...paramsObj, owner: apiAuth.owner });
  const body = { ...bodyObj };
  const headers = new Headers();
  // headers.set(
  //   "Authorization",
  //   "Basic " + btoa(apiAuth.userId + ":" + apiAuth.token)
  // );
  headers.set("Content-Type", "application/json");
  headers.set("Accept", "application/json");
  const response = await fetch(`${devHost ? devHost + "/" : ""}${cleanedUrl}`, {
    headers,
    mode: "cors",
    credentials: "include",
    method: "POST",
    body: JSON.stringify(body),
  });
  if (response.ok) {
    const json = await response.text();
    return JSON.parse(json, utils.jsonDateReviver);
  } else {
    throw new Error(
      `invalide json dans fetch  post ${url} with body ${JSON.stringify(body)}`
    );
  }
}
